import { CaseStudyType } from "types/cms";

import Section from "components/containers/Section";
import { ContentfulImage } from "components/images/ContentfulImage";

type CaseStudyProps = {
  caseStudy: CaseStudyType;
};

const CaseStudy = ({ caseStudy }: CaseStudyProps) => {
  if (!caseStudy) return null;

  return (
    <div className="bg-paper py-16 md:py-20 lg:py-32">
      <Section className="flex flex-col gap-10 md:gap-20">
        <div className="flex flex-col-reverse lg:flex-row gap-10 md:gap-24">
          <div className="relative aspect-[1.5] lg:aspect-[0.88] rounded-[40px] overflow-hidden lg:w-56">
            <ContentfulImage
              image={caseStudy.mainImage}
              layout="fill"
              objectFit="cover"
              sizes="(min-width: 768px) 256px, 100vw"
            />
          </div>
          <div className="flex flex-col gap-7 flex-1">
            <h2 className="text-clean-tech font-bold text-body16">
              Case study
            </h2>
            <h3 className="font-black text-heading3 sm:text-heading2 leading-tight">
              {caseStudy.title}
            </h3>
            <p className="text-body16 leading-relaxed">
              {caseStudy.description}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-10">
          <div>
            <h4 className="text-heading3 md:text-heading2 font-black mb-5">
              The problem
            </h4>
            <p className="text-body16 leading-relaxed">{caseStudy.problem}</p>
          </div>
          <div>
            <h4 className="text-heading3 md:text-heading2 font-black mb-5">
              The solution
            </h4>
            <p className="text-body16 leading-relaxed">{caseStudy.solution}</p>
          </div>
        </div>
        <div>
          <div className="text-navy font-bold text-body21 md:pl-5 mb-5">
            The result
          </div>
          <div className="grid lg:grid-cols-3 gap-5">
            {caseStudy.stats.map((stat) => (
              <div
                className={
                  "flex-1 bg-white rounded-[20px] p-10 flex flex-col justify-end " +
                  "text-heading2 font-black font-serif leading-tight " +
                  "shadow-30px-beige-35"
                }
                key={stat}
              >
                {stat}
              </div>
            ))}
          </div>
        </div>
        <blockquote className="relative -mt-5 p-16 md:-mt-16 md:p-24">
          <div className="absolute top-0 left-0 text-clean-tech font-serif font-black text-[92px]">
            &ldquo;
          </div>
          <p className="text-body-21 font-light leading-loose">
            {caseStudy.quote}
          </p>
          <div className="absolute bottom-0 right-0 text-clean-tech font-serif font-black text-[92px]">
            &rdquo;
          </div>
          <h5 className="text-heading5 text-left font-black my-5">
            {caseStudy.quotePersonName}
          </h5>
        </blockquote>
      </Section>
    </div>
  );
};

export default CaseStudy;
