import { HomePageType } from "types/cms";

import { useLoadingEffectRouter } from "helpers/utils/routing";

import Section from "components/containers/Section";
import { ContentfulImage } from "components/images/ContentfulImage";

type FeatureProps = {
  cmsContent: HomePageType;
};

const Feature = ({ cmsContent }: FeatureProps) => {
  const router = useLoadingEffectRouter();

  return (
    <div className="bg-paper py-16 md:py-20 lg:py-32 md:min-h-[400px] lg:min-h-[600px] xl:min-h-[800px]">
      <Section className="flex flex-col md:flex-row md:gap-28">
        <div className="md:w-3/5 sm:-ml-16 -ml-12">
          <ContentfulImage
            image={cmsContent.feature1SectionImage}
            height={
              cmsContent.feature1SectionImage.fields.file.details.image?.height
            }
            width={
              cmsContent.feature1SectionImage.fields.file.details.image?.width
            }
            layout="responsive"
            sizes="(min-width: 768px) 620px, 100vw"
          />
        </div>
        <div className="md:w-2/5 pt-16 md:pt-32">
          <h2 className="text-navy text-heading3 sm:text-heading2 font-black leading-tight mb-7">
            {cmsContent.feature1SectionTitle}
          </h2>
          <p className="text-navy text-body16 mb-11">
            {cmsContent.feature1SectionSubtitle}
          </p>
          <button
            onClick={() => router("/contact")}
            className={
              "py-2.5 px-8 border-2 outline-none rounded-full border-clean-tech w-full md:w-auto " +
              "text-navy text-body16 font-normal " +
              "hover:bg-clean-tech hover:text-white transition-colors"
            }
          >
            {cmsContent.feature1SectionCta}
          </button>
        </div>
      </Section>
    </div>
  );
};

export default Feature;
