import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";
import { Asset } from "contentful";

import { HomePageType } from "types/cms";

import { Colors } from "helpers/utils/uiSetup";

import LinkWithArrow from "components/buttons/LinkWithArrow";
import Section from "components/containers/Section";
import { ContentfulImage } from "components/images/ContentfulImage";

type BusinessImpactProps = {
  cmsContent: HomePageType;
};

type BusinessImpactCard = {
  title: string;
  icon?: Asset;
  text: Document;
};

// Convert a loose rich text structure to structured card data
// This is a slightly fiddly approach, be careful copying it. It was used here
// So that we didn't reach the field limit on the HomePage model
const convertRichTextToCard = (document: Document): BusinessImpactCard[] => {
  const cards: BusinessImpactCard[] = [];

  for (let i = 0; i < document.content.length; i++) {
    const node = document.content[i];

    // Use the heading 2 as the defining element of a card, we build the card around that
    // using look aheads and look behinds to get the corresponding icons and text
    if (node.nodeType === BLOCKS.HEADING_2 && "value" in node.content[0]) {
      const title = node.content[0].value;

      // The icon should be the previous element, get that here
      const icon = document.content[i - 1]?.data?.target;

      // Construct a Contentful document with the remaining text components so we can
      // easily render line breaks, links etc.
      const text: Document = {
        content: [],
        nodeType: BLOCKS.DOCUMENT,
        data: {},
      };

      let nextNode = document.content[i + 1];
      // Loop through all nodes until we reach the next heading or we run out of nodes
      while (nextNode && nextNode?.nodeType !== BLOCKS.HEADING_2) {
        i++;
        text.content.push(nextNode);
        nextNode = document.content[i + 1];
      }

      // Create the card
      cards.push({ title, icon, text });
    }
  }

  return cards;
};

const BusinessImpact = ({ cmsContent }: BusinessImpactProps) => {
  const cards = convertRichTextToCard(cmsContent.businessImpactSectionContent);

  return (
    <div className="bg-deep-blue text-white py-16 md:py-20 lg:py-32">
      <Section>
        <h1 className="font-black text-heading2 md:text-heading1.5 lg:text-heading1 mb-16 sm:mb-24">
          {cmsContent.businessImpactSectionTitle}
        </h1>
        <div className="grid md:grid-cols-2 gap-10 sm:gap-24">
          {cards.map(({ title, icon, text }) => (
            <div key={title}>
              {!!icon && (
                <div className="flex items-center justify-center aspect-square w-20 bg-navy border border-metal-grey rounded-xl mb-10">
                  <div className="relative aspect-square w-9">
                    <ContentfulImage image={icon} layout="fill" />
                  </div>
                </div>
              )}
              <h3 className="font-black text-heading4 sm:text-heading3 leading-tight mb-5">
                {title}
              </h3>
              {documentToReactComponents(text, {
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => (
                    <div className="text-body16 mb-3 leading-loose">
                      {children}
                    </div>
                  ),
                  [INLINES.HYPERLINK]: (node) => (
                    <LinkWithArrow
                      text={
                        "value" in node.content[0] ? node.content[0].value : ""
                      }
                      href={node.data.uri}
                      color={Colors.oceanRescue}
                      className="mt-5"
                    />
                  ),
                },
              })}
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default BusinessImpact;
