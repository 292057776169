import { createRef, useEffect, useRef } from "react";

import { Asset } from "contentful";

import BrandImage from "components/blocks/shared/BrandImage";

type BrandsCarouselProps = {
  brands: Asset[];
};

const BrandsCarousel = ({ brands }: BrandsCarouselProps) => {
  // Brands array
  const formattedBrands = [...brands, ...brands, ...brands];

  // Set refs
  const brandsRef = useRef(formattedBrands.map(() => createRef()));
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    const baseBrandRef = brandsRef.current[brands.length].current;
    carousel.scrollLeft = (baseBrandRef as HTMLElement).offsetLeft;
  }, [brands.length]);

  useEffect(() => {
    const carousel = carouselRef.current;
    const midBrandRef = brandsRef.current[brands.length].current;
    const startScrollPos = (midBrandRef as HTMLElement).offsetLeft;
    const rightScrollPos =
      startScrollPos +
      (midBrandRef as HTMLElement).offsetWidth -
      carousel.clientWidth;
    const leftBrandRef = brandsRef.current[0].current;
    const rightBrandRef = brandsRef.current[brands.length * 2].current;

    const observerOptions = {
      root: carousel,
      rootMargin: "0px",
      threshold: 0.9999,
    };

    const leftObserverCallback = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && leftBrandRef) {
        carousel.scrollLeft = startScrollPos;
      }
    };
    const leftObserver = new IntersectionObserver(
      leftObserverCallback,
      observerOptions
    );
    if (leftBrandRef) {
      leftObserver.observe(leftBrandRef as Element);
    }

    const rightObserverCallback = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && rightBrandRef) {
        carousel.scrollLeft = rightScrollPos;
      }
    };
    const rightObserver = new IntersectionObserver(
      rightObserverCallback,
      observerOptions
    );
    if (rightBrandRef) {
      rightObserver.observe(rightBrandRef as Element);
    }
  }, [brands.length]);

  return (
    <div
      ref={carouselRef}
      className="relative flex h-10 gap-x-20 overflow-x-scroll no-scrollbar"
    >
      {formattedBrands.map((brand, index) => (
        <BrandImage key={index} ref={brandsRef.current[index]} image={brand} />
      ))}
    </div>
  );
};

export default BrandsCarousel;
