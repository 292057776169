import { LegacyRef, forwardRef } from "react";

import Image from "next/image";

import { ProjectType } from "types/project";

import LinkWithLoading from "components/buttons/LinkWithLoading";
import VerifiedIcon from "components/images/VerifiedIcon";

type ProjectCardProps = {
  project: ProjectType;
  isDark?: boolean;
};

const ProjectCard = forwardRef(
  ({ project, isDark }: ProjectCardProps, ref?: LegacyRef<HTMLDivElement>) => (
    <div
      ref={ref}
      className={
        "flex flex-col p-2.5 rounded-2xl h-full " +
        "transition-shadow duration-500 " +
        `${
          isDark
            ? "bg-navy hover:bg-white/90 hover:text-navy shadow-30px-black-60 hover:shadow-30px-navy-60"
            : "bg-white/90 shadow-30px-beige-35 hover:shadow-30px-black-20 "
        }`
      }
      style={{ WebkitTransform: "translate3d(0,0,0)" }} // Forces hardware acceleration on iOS
    >
      <LinkWithLoading href={`/projects/${project.slug}`}>
        <div className="relative aspect-video rounded-xl overflow-hidden mb-2">
          <Image
            src={`https://${project.contentful_image.url}`}
            layout="fill"
            objectFit="cover"
            sizes="(min-width:1024px) 25vw, (min-width:768px) 33vw, (min-width:640px) 50vw, 100vw"
          />
          {!!project.verified_by && (
            <div className="absolute h-6 w-6 top-3 right-3">
              <VerifiedIcon />
            </div>
          )}
        </div>
        <div className="p-2.5">
          <h3 className="font-black text-heading5 mb-2.5">
            {project.short_name || project.name}
          </h3>
          <p className="text-body14 leading-loose">{project.description}</p>
        </div>
      </LinkWithLoading>
    </div>
  )
);

ProjectCard.displayName = "ProjectCard";

export default ProjectCard;
