import { useEffect, useState } from "react";
import Masonry from "react-masonry-css";

import { ActionItemType } from "types/action";
import { HomePageType } from "types/cms";

import ActionItem from "components/actionFeed/ActionItem";
import Section from "components/containers/Section";

interface HomePageProps {
  actionFeed: ActionItemType[];
  cmsContent: HomePageType;
}

const ActionFeedSection = ({ actionFeed, cmsContent }: HomePageProps) => {
  const breakpointColumns = {
    default: 5,
    640: 1,
  };

  // Remove layout flicker by rendering columns only after viewport width is available
  const [windowAvailable, setWindowAvailable] = useState(null);
  useEffect(() => {
    setWindowAvailable(typeof window !== "undefined");
  }, []);

  return (
    <div className="bg-paper-texture">
      <Section className="text-center pt-14 pb-10 sm:pt-20 sm:pb-16 lg:pt-28 lg:pb-16">
        <h1 className="text-heading2 sm:text-heading1 font-black">
          {cmsContent.actionFeedSectionTitle}
        </h1>
        <p className="text-body18 sm:text-body21 leading-relaxed max-w-4xl mx-auto pt-7">
          {cmsContent.actionFeedSectionSubtitle}
        </p>
      </Section>
      <Section className="sm:max-w-7xl">
        <div className="overflow-hidden h-[800px]">
          {windowAvailable ? (
            <Masonry
              breakpointCols={breakpointColumns}
              className="flex sm:-ml-[190px] lg:-ml-[230px] xl:-ml-[270px] sm:items-center overflow-hidden"
              columnClassName="sm:ml-5 min-w-[325px] min-w-[300px]"
            >
              {actionFeed.map((item) => {
                return <ActionItem key={item.id} item={item} />;
              })}
            </Masonry>
          ) : (
            <div className="h-[700px]"></div>
          )}
        </div>
      </Section>
    </div>
  );
};

export default ActionFeedSection;
