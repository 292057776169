import { HomePageType } from "types/cms";

import BrandsCarousel from "components/blocks/shared/BrandsCarousel";
import Section from "components/containers/Section";

type BrandsProps = {
  cmsContent: HomePageType;
};

const Brands = ({ cmsContent }: BrandsProps) => {
  return (
    <div className="bg-[#FCFDFF] py-16 md:py-20 lg:py-32">
      <Section>
        <h2 className="font-black text-navy leading-tight text-heading3 sm:text-heading2">
          {cmsContent.brandCarouselTitle}
        </h2>
      </Section>
      <div className="max-w-7xl mx-auto py-14 -mb-14">
        <BrandsCarousel brands={cmsContent.brandCarouselImages} />
      </div>
    </div>
  );
};

export default Brands;
