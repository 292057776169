import { HomePageType } from "types/cms";

import Section from "components/containers/Section";
import { ContentfulImage } from "components/images/ContentfulImage";

type EventsProps = {
  cmsContent: HomePageType;
};

const Events = ({ cmsContent }: EventsProps) => {
  return (
    <div className="bg-navy text-white py-16 md:py-20 lg:py-32">
      <Section>
        <h1 className="font-black text-heading2 sm:text-heading1 mb-5 text-center">
          {cmsContent.eventsSectionTitle}
        </h1>
        <div className="text-body21 leading-tight text-center">
          {cmsContent.eventsSectionSubtitle}
        </div>
        <div className="flex flex-col md:flex-row mt-20 gap-10 md:gap-5">
          <div className="relative md:w-1/2 lg:w-3/5 md:self-center">
            <ContentfulImage
              image={cmsContent.eventsSectionImage}
              height={
                cmsContent.eventsSectionImage.fields.file.details.image?.height
              }
              width={
                cmsContent.eventsSectionImage.fields.file.details.image?.width
              }
              layout="responsive"
              sizes="(min-width: 768px) 580px, 100vw"
            />
          </div>
          <div className="flex flex-col md:w-1/2 lg:2/5 gap-5">
            {cmsContent.eventsSectionHeadings.map((h) => (
              <div
                className={
                  "flex-1 flex flex-col justify-end p-10 " +
                  "border border-deep-blue shadow-30px-black-60 rounded-[40px]"
                }
                key={h}
              >
                <h3 className="font-black text-heading4 sm:text-heading3 leading-tight">
                  {h}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Events;
