const VerifiedIcon = ({ color = "white" }: { color?: string }) => {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.32812 13.4985L12.1099 16.2763L17.6734 10.7207"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 1C16.7489 3.87024 20.9891 5.36468 25.3225 5.16667C25.9533 7.30974 26.1464 9.55759 25.8901 11.7766C25.6338 13.9957 24.9334 16.1407 23.8306 18.0841C22.7278 20.0275 21.2449 21.7297 19.4703 23.0896C17.6956 24.4494 15.6653 25.4392 13.5 26C11.3347 25.4392 9.30439 24.4494 7.52973 23.0896C5.75506 21.7297 4.27224 20.0275 3.1694 18.0841C2.06655 16.1407 1.36618 13.9957 1.1099 11.7766C0.853618 9.55759 1.04666 7.30974 1.67755 5.16667C6.01087 5.36468 10.2511 3.87024 13.5 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VerifiedIcon;
