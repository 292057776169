import { LegacyRef, createRef, useEffect, useRef } from "react";

import { HomePageType } from "types/cms";
import { ProjectType } from "types/project";

import ProjectCard from "components/cards/ProjectCard";
import Section from "components/containers/Section";
import { ContentfulImage } from "components/images/ContentfulImage";

type ProjectsProps = {
  cmsContent: HomePageType;
  projects: ProjectType[];
};

const Projects = ({ cmsContent, projects }: ProjectsProps) => {
  // Insert projects array
  const infiniteProjects = [...projects, ...projects, ...projects];

  // Set refs
  const projectsRef = useRef(infiniteProjects.map(() => createRef()));
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    const baseProjectsRef = projectsRef.current[projects.length].current;
    carousel.scrollLeft = (baseProjectsRef as HTMLElement).offsetLeft;
  }, [projects.length]);

  useEffect(() => {
    const carousel = carouselRef.current;
    const midProjectRef = projectsRef.current[projects.length].current;
    const startScrollPos = (midProjectRef as HTMLElement).offsetLeft;
    const rightScrollPos =
      startScrollPos +
      (midProjectRef as HTMLElement).offsetWidth -
      carousel.clientWidth;
    const leftProjectRef = projectsRef.current[0].current;
    const rightProjectRef = projectsRef.current[projects.length * 2].current;

    const observerOptions = {
      root: carousel,
      rootMargin: "0px",
      threshold: 0.9999,
    };

    const leftObserverCallback = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && leftProjectRef) {
        carousel.scrollLeft = startScrollPos;
      }
    };
    const leftObserver = new IntersectionObserver(
      leftObserverCallback,
      observerOptions
    );
    if (leftProjectRef) {
      leftObserver.observe(leftProjectRef as Element);
    }

    const rightObserverCallback = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && rightProjectRef) {
        carousel.scrollLeft = rightScrollPos;
      }
    };
    const rightObserver = new IntersectionObserver(
      rightObserverCallback,
      observerOptions
    );
    if (rightProjectRef) {
      rightObserver.observe(rightProjectRef as Element);
    }
  }, [projects.length]);

  return (
    <div className="bg-navy py-16 md:py-20 lg:py-32 text-white">
      <Section>
        <h1 className="text-heading2 sm:text-heading1 font-black mb-4">
          {cmsContent.projectsSectionTitle}
        </h1>
        <p className="text-body16 md:w-3/5 mb-14">
          {cmsContent.projectsSectionSubtitle}
        </p>
        <ContentfulImage
          image={cmsContent.projectsSectionImage}
          height={
            cmsContent.projectsSectionImage.fields.file.details.image?.height
          }
          width={
            cmsContent.projectsSectionImage.fields.file.details.image?.width
          }
          layout="responsive"
          sizes="(min-width: 1024px), 100vw"
        />
      </Section>
      <div
        ref={carouselRef}
        className="flex flex-row overflow-x-scroll no-scrollbar -mt-28 pt-16 pb-8 pr-10 gap-2.5 max-w-7xl mx-auto"
      >
        {infiniteProjects.map((project, index) => (
          <div
            ref={projectsRef.current[index] as LegacyRef<HTMLDivElement>}
            key={project.slug + index}
            className="flex-none w-64 z-10"
          >
            <ProjectCard project={project} isDark />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
