import { Asset } from "contentful";

import { HomePageType } from "types/cms";

import { Colors } from "helpers/utils/uiSetup";

import Section from "components/containers/Section";
import { ContentfulImage } from "components/images/ContentfulImage";

type StepProps = {
  title: string;
  image: Asset;
  index: number;
};

const Step = ({ title, image, index }: StepProps) => {
  return (
    <div className="flex flex-col gap-5 md:flex-row md:h-[480px] p-10 md:py-14 rounded-[40px] border border-deep-blue shadow-30px-black-60">
      <div className="flex flex-col flex-1 justify-between">
        <div
          className="font-serif font-black text-heading2 md:text-heading1 text-navy"
          style={{ WebkitTextStroke: `${Colors.wilderness} 1px` }}
        >
          0{index + 1}
        </div>
        <h2 className="text-heading3 md:text-heading2 leading-tight">
          {title}
        </h2>
      </div>
      <div className="relative flex-1 aspect-[1.4]">
        <ContentfulImage
          image={image}
          layout="fill"
          objectFit="contain"
          objectPosition="left"
          sizes="(min-width: 768px) 480px, 100vw"
        />
      </div>
    </div>
  );
};

type StepsProps = {
  cmsContent: HomePageType;
};

const Steps = ({ cmsContent }: StepsProps) => {
  return (
    <div className="bg-navy text-white py-16 md:py-20 lg:py-32">
      <Section className="flex flex-col gap-12">
        {cmsContent.stepTitles.map((title, i) => (
          <Step
            key={title}
            title={title}
            image={cmsContent.stepImages[i]}
            index={i}
          />
        ))}
      </Section>
    </div>
  );
};

export default Steps;
