import { HomePageType } from "types/cms";

import Section from "components/containers/Section";
import { ContentfulImage } from "components/images/ContentfulImage";

type BrandValueProps = {
  cmsContent: HomePageType;
};

const BrandValue = ({ cmsContent }: BrandValueProps) => {
  return (
    <div className="bg-navy text-white py-16 md:py-20 lg:py-32">
      <Section>
        <div className="md:w-3/5">
          <h1 className="text-[48px] sm:text-heading1 lg:text-[80px] xl:text-[92px] leading-[1.2] text-metal-grey mb-6">
            {cmsContent.brandSectionTitleLine1}
            <br />
            <span className="text-white">
              {cmsContent.brandSectionTitleLine2}
            </span>
          </h1>
          <p className="text-white text-body16 leading-relaxed">
            {cmsContent.brandSectionSubtitle}
          </p>
        </div>
        <div className="flex flex-col lg:flex-row gap-5 mt-20">
          {cmsContent.brandSectionHeadings.map((heading, i) => (
            <div
              className={
                "flex-1 flex flex-col p-11 gap-16 " +
                "border border-deep-blue shadow-30px-black-60 rounded-[40px]"
              }
              key={heading}
            >
              <div className="relative aspect-[1.5]">
                <ContentfulImage
                  image={cmsContent.brandSectionImages[i]}
                  layout="fill"
                  objectFit="cover"
                  sizes="(min-width: 1024px) 256px, 100vw"
                />
              </div>
              <h3 className="font-black text-heading4 sm:text-heading3 leading-tight">
                {heading}
              </h3>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default BrandValue;
