import { HomePageType } from "types/cms";

import { ContentfulImage } from "components/images/ContentfulImage";

type HeroProps = {
  cmsContent: HomePageType;
};

const Hero = ({ cmsContent }: HeroProps) => {
  return (
    <div className="bg-navy overflow-x-hidden pt-36 md:pt-42 lg:pt-52 pb-16 md:pb-10 lg:pb-16 xl:pb-32">
      <div className="flex flex-col lg:flex-row md:items-end max-w-7xl px-5 sm:px-10 mx-auto gap-10">
        <div className="lg:w-3/5 z-10">
          <h1 className="text-[44px] md:text-[80px] lg:text-[72px] xl:text-[92px] leading-[1.2] text-metal-grey mb-6">
            {cmsContent.titleLine1}
            <br />
            <span className="text-white">{cmsContent.titleLine2}</span>
          </h1>
          <p className="md:w-4/5 lg:w-full text-white text-body21 leading-relaxed">
            {cmsContent.subtitle}
          </p>
        </div>
        <div className="md:w-4/5 lg:w-2/5 -mr-5 sm:-mr-10 md:mr-0">
          <ContentfulImage
            image={cmsContent.titleImage}
            height={cmsContent.titleImage.fields.file.details.image?.height}
            width={cmsContent.titleImage.fields.file.details.image?.width}
            layout="responsive"
            sizes="(min-width: 1024px) 40vw, (min-width: 768px) 80vw, 100vw"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
