import { IconArrowRight } from "@tabler/icons";

import { Colors } from "helpers/utils/uiSetup";

import LinkWithLoading from "components/buttons/LinkWithLoading";

type LinkWithArrowProps = {
  href: string;
  text: string;
  className?: string;
  style?: object;
  color?: string;
};

const LinkWithArrow = ({
  href,
  text,
  className,
  style,
  color = Colors.wilderness,
}: LinkWithArrowProps) => {
  const sortedStyle = { ...style };
  const sortedClassName = [
    "flex items-center font-medium space-x-2 cursor-pointer",
    color === Colors.wilderness
      ? "hover:text-wilderness"
      : color === Colors.oceanRescue
      ? "hover:text-ocean-rescue"
      : color === Colors.cleanTech
      ? "hover:text-clean-tech"
      : color === Colors.newHorizons
      ? "hover:text-new-horizons"
      : "",
    className ? className : "",
  ].join(" ");
  return (
    <LinkWithLoading href={href}>
      <span className={sortedClassName} style={sortedStyle}>
        {text}
        <div className="ml-1 inline-flex h-5 w-5 items-center justify-center">
          <IconArrowRight color={color} />
        </div>
      </span>
    </LinkWithLoading>
  );
};

export default LinkWithArrow;
