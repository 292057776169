import { HomePageType } from "types/cms";

import LinkWithArrow from "components/buttons/LinkWithArrow";
import Section from "components/containers/Section";
import { ContentfulImage } from "components/images/ContentfulImage";

type GetYourProjectOnPinwheelProps = {
  cmsContent: HomePageType;
};

const GetYourProjectOnPinwheel = ({
  cmsContent,
}: GetYourProjectOnPinwheelProps) => {
  return (
    <div className="bg-navy pb-16 md:pb-20 lg:pb-32 text-white overflow-x-hidden">
      <Section className="flex flex-col md:flex-row md:gap-10">
        <div className="md:w-2/5 self-center">
          <h2 className="text-heading3 sm:text-heading2 font-black mb-4">
            {cmsContent.getYourProjectOnPinwheelSectionTitle}
          </h2>
          <p className="text-body16 mb-14">
            {cmsContent.getYourProjectOnPinwheelSectionSubtitle}
          </p>
        </div>
        <div className="md:w-3/5 -mr-10 xl:mr-0">
          <ContentfulImage
            image={cmsContent.getYourProjectOnPinwheelSectionImage}
            height={
              cmsContent.getYourProjectOnPinwheelSectionImage.fields.file
                .details.image?.height
            }
            width={
              cmsContent.getYourProjectOnPinwheelSectionImage.fields.file
                .details.image?.width
            }
            layout="responsive"
            sizes="(min-width: 1024px) 640px, (min-width: 768px) 60vw, 100vw"
          />
        </div>
      </Section>
      <Section className="flex flex-col md:flex-row gap-5 mt-20">
        <div
          className={
            "flex-1 flex flex-col justify-between p-10 " +
            "border border-deep-blue shadow-30px-black-60 rounded-[40px]"
          }
        >
          <div>
            <h3 className="text-heading3 sm:text-heading2 font-black mb-10">
              {cmsContent.getYourProjectVerifiedOnPinwheelHeading}
            </h3>
            <LinkWithArrow
              href="/project-selection"
              text={cmsContent.getYourProjectVerifiedOnPinwheelCta}
            />
          </div>
          <div className="max-w-xs">
            <ContentfulImage
              image={cmsContent.getYourProjectVerifiedOnPinwheelImage}
              height={
                cmsContent.getYourProjectVerifiedOnPinwheelImage.fields.file
                  .details.image?.height
              }
              width={
                cmsContent.getYourProjectVerifiedOnPinwheelImage.fields.file
                  .details.image?.width
              }
              sizes="(min-width: 1024px) 440px, (min-width: 768px) 50vw, 100vw"
            />
          </div>
        </div>
        <div
          className={
            "flex-1 flex flex-col items-end p-10 " +
            "border border-deep-blue shadow-30px-black-60 rounded-[40px]"
          }
        >
          <div className="max-w-sm">
            <ContentfulImage
              image={cmsContent.getYourProjectOnPinwheelSectionCardImage}
              height={
                cmsContent.getYourProjectOnPinwheelSectionCardImage.fields.file
                  .details.image?.height
              }
              width={
                cmsContent.getYourProjectOnPinwheelSectionCardImage.fields.file
                  .details.image?.width
              }
              sizes="(min-width: 1024px) 440px, (min-width: 768px) 50vw, 100vw"
            />
          </div>
          <h3 className="text-heading3 sm:text-heading2 font-black mt-10">
            {cmsContent.getYourProjectOnPinwheelSectionCardHeading}
          </h3>
        </div>
      </Section>
    </div>
  );
};

export default GetYourProjectOnPinwheel;
