import { forwardRef } from "react";

import { Asset } from "contentful";

import { ContentfulImage } from "components/images/ContentfulImage";

type BrandImageProps = {
  image: Asset;
};

const BrandImage = ({ image }: BrandImageProps, ref) => {
  return (
    <div
      ref={ref}
      className="flex-none flex items-center"
      style={{ WebkitTransform: "translate3d(0,0,0)" }} // Forces hardware acceleration on iOS
    >
      <ContentfulImage
        image={image}
        height={40}
        width={image.fields.file.details.image.width / 3}
      />
    </div>
  );
};

export default forwardRef(BrandImage);
