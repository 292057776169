import { Theme } from "types/ui";

import { useLoadingEffectRouter } from "helpers/utils/routing";

import Section from "components/containers/Section";

type BookADemoProps = {
  title: string;
  theme: Theme;
  ctaText: string;
};

const BORDER_CLASSES: { [key in Theme]: string } = {
  [Theme.wilderness]: "border-wilderness",
  [Theme.oceanRescue]: "border-ocean-rescue",
  [Theme.cleanTech]: "border-clean-tech",
  [Theme.newHorizons]: "border-new-horizons",
};

const BACKGROUND_CLASSES: { [key in Theme]: string } = {
  [Theme.wilderness]: "bg-wilderness",
  [Theme.oceanRescue]: "bg-ocean-rescue",
  [Theme.cleanTech]: "bg-clean-tech",
  [Theme.newHorizons]: "bg-new-horizons",
};

const BookADemo = ({ title, theme, ctaText }: BookADemoProps) => {
  const router = useLoadingEffectRouter();
  return (
    <div className="bg-[#0C0932] py-16 md:py-20 lg:py-32 text-white">
      <Section className="flex items-center flex-col md:flex-row gap-10">
        <div className="flex-1">
          <h2 className="font-black text-heading3 md:text-heading2 leading-tight text-center sm:text-left max-w-xl">
            {title}
          </h2>
        </div>
        <div>
          <button
            onClick={() => router("/contact")}
            className={
              `py-2.5 px-16 border outline-none rounded-full ${BORDER_CLASSES[theme]} ` +
              "text-white text-body16 font-medium " +
              `hover:${BACKGROUND_CLASSES[theme]} hover:text-navy transition-colors`
            }
          >
            {ctaText}
          </button>
        </div>
      </Section>
    </div>
  );
};

export default BookADemo;
