import { useEffect } from "react";

import { GetStaticProps } from "next";

import { getHomePage, getPublicActions } from "cms";

import { ActionItemType } from "types/action";
import { PaginatedResponse } from "types/api";
import { HomePageType } from "types/cms";
import { ProjectType } from "types/project";
import { Theme } from "types/ui";

import { useUIContext } from "contexts/UIContext";

import { getClientSideApiFetcher } from "helpers/apis/apiFetcher";

import ActionFeedSection from "components/blocks/home/ActionFeedSection";
import BookADemo from "components/blocks/home/BookADemo";
import BrandValue from "components/blocks/home/BrandValue";
import Brands from "components/blocks/home/Brands";
import BusinessImpact from "components/blocks/home/BusinessImpact";
import CaseStudy from "components/blocks/home/CaseStudy";
import Events from "components/blocks/home/Events";
import Feature from "components/blocks/home/Feature";
import GetYourProjectOnPinwheel from "components/blocks/home/GetYourProjectOnPinwheel";
import Hero from "components/blocks/home/Hero";
import Projects from "components/blocks/home/Projects";
import Steps from "components/blocks/home/Steps";
import HeadWithTags from "components/meta/HeadWithTags";

type HomeProps = {
  actionFeed: ActionItemType[];
  cmsContent: HomePageType;
  projects: ProjectType[];
};

const Home = ({ actionFeed, cmsContent, projects }: HomeProps) => {
  const { setNavbarStyle, setFooterStyle } = useUIContext();

  useEffect(() => {
    setNavbarStyle({
      navbarColor: "white",
      navbarMobileHamburgerIconColor: "white",
      navbarTabletHamburgerIconColor: "white",
      navbarLogo: "primary",
    });
    setFooterStyle({
      showShortFooter: false,
      footerBackgroundColor: "navy",
    });
  }, []);

  return (
    <>
      <HeadWithTags
        title={cmsContent.metaTitle}
        description={cmsContent.metaDescription}
        noTitlePrefix={true}
      />
      <main className="bg-navy">
        <Hero cmsContent={cmsContent} />
        <Steps cmsContent={cmsContent} />
        <BookADemo
          title={cmsContent.demoSectionTitle}
          ctaText={cmsContent.demoButtonCta}
          theme={Theme.wilderness}
        />
        <ActionFeedSection actionFeed={actionFeed} cmsContent={cmsContent} />
        <BrandValue cmsContent={cmsContent} />
        <Brands cmsContent={cmsContent} />
        <Feature cmsContent={cmsContent} />
        <Events cmsContent={cmsContent} />
        <BusinessImpact cmsContent={cmsContent} />
        <CaseStudy caseStudy={cmsContent.clientCaseStudies[0]?.fields} />
        <BookADemo
          title={cmsContent.demoSectionTitle}
          ctaText={cmsContent.demoButtonCta}
          theme={Theme.newHorizons}
        />
        <Projects cmsContent={cmsContent} projects={projects} />
        <GetYourProjectOnPinwheel cmsContent={cmsContent} />
        <CaseStudy caseStudy={cmsContent.projectCaseStudies[0]?.fields} />
        <BookADemo
          title={cmsContent.projectsGetVerifiedTitle}
          ctaText={cmsContent.projectsGetVerifiedButtonCta}
          theme={Theme.wilderness}
        />
      </main>
    </>
  );
};

export const getStaticProps: GetStaticProps = async function ({ preview }) {
  const actionFeed = await getPublicActions(10, preview);
  const cmsContent = await getHomePage(preview);
  const projects = (
    await getClientSideApiFetcher().get<PaginatedResponse<ProjectType>>(
      `/api/projects/`
    )
  ).data.body.results;

  // Randomly choose a case study for each time the page is built
  cmsContent.clientCaseStudies = cmsContent.clientCaseStudies
    ? [
        cmsContent.clientCaseStudies[
          Math.floor(Math.random()) * cmsContent.clientCaseStudies.length
        ],
      ]
    : [];

  // Randomly choose a case study for each time the page is built
  cmsContent.projectCaseStudies = cmsContent.projectCaseStudies
    ? [
        cmsContent.projectCaseStudies[
          Math.floor(Math.random()) * cmsContent.projectCaseStudies.length
        ],
      ]
    : [];

  return {
    props: {
      actionFeed,
      cmsContent,
      projects,
    },
  };
};

export default Home;
